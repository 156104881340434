import { MDXProvider } from "@mdx-js/react";
import React from "react";

export const components = {
  h1: ({ children }) => <h1 className="text-4xl font-bold p-2 text-black">{children}</h1>,
  h2: ({ children }) => <h2 className="text-2xl font-bold p-2 text-black">{children}</h2>,
  p: ({ children }) => <p className="p-5 text-black">{children}</p>,
  a: ({ children, href }) => (
    <a className="underline text-bold hover:text-green-500"
      style={{ textDecoration: 'underline' }}
      href={href}>
      {children}
    </a>
  ),
  ul: ({ children }) => <ul className="text-base pl-5 pr-5 p-2">{children}</ul>,
  li: ({ children }) => <li className="text-base p-1">- {children}</li>,
};

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);
